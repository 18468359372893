body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
input,
select,
textarea#ruleTextarea-0,
input#nameInput,
input::placeholder,
label,
textarea.ruleTextarea {
  font-family: 'Poppins', sans-serif !important;
}

.form-control,
.form-select {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #b1adad;
  outline: none;
  // transition: border-color 0.3s ease;
  transition: border-color ease-in-out 0.45s, box-shadow ease-in-out 0.45s;
  font-family: auto;
}

.form-label {
  font-family: auto;
  //padding-top: 25px !important ;
}

.errorMessage {
  font-family: auto;
}
/* Add bottom border */
.form-control:focus,
.form-select:focus {
  border-bottom: 2px solid #00c292 !important;
  box-shadow: none;
}

.ant-table-content .ant-table-thead > tr > th {
  background-color: #ffff !important;
}

.ant-table-content .ant-table-tbody > tr:nth-child(even) {
  background-color: #f1f1f1 !important;
}

.ant-table-content > table {
  border: 1px solid #c9bbbb;
  box-shadow: 10px 8px 8px rgb(24 26 24 / 45%);
}

.select_question_section > .form-floating > .form-label {
  padding: 0 !important ;
  margin: 0 !important ;
  height: 35% !important ;
}
.ant-table-content {
  overflow-x: auto;
}
.input-group-text {
  background-color: rgb(255, 255, 255) !important;
  border: none !important;
}

/***new styles -color -related ***/
a.nav-link.dashHeader {
  color: #333 !important;
}
a.nav-link {
  color: #ffff !important;
}
.ant-table-content {
  overflow-x: auto;
}
.sidebar.sidebar-fixed {
  background: #ffff;
  color: #ffff;
}
.sidebar-brand.d-none.d-md-flex {
  background: #ffff;
}

li.nav-title {
  color: white !important;
}

:where(.css-dev-only-do-not-override-17sses9).ant-table-wrapper .ant-table-column-title:hover {
  color: #f56001 !important;
}
.ant-btn-primary {
  background: #f56001;
  color: #ffff;
}
:where(.css-dev-only-do-not-override-17sses9).ant-btn-primary:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background: #f56001;
  color: #ffff;
}
button.header-toggler.ps-1 svg {
  color: #ffff;
}
img.avatar-img {
  width: 2rem;
}
a.btn.btn-primary {
  background: #f56001;
  border: solid 1px #f56001 !important;
}
/*/.card.bg-primary.text-high-emphasis-inverse.mb-4 {
  background: linear-gradient(45deg, #39685f, #07555e);
}*/
.card.bg-primary.text-high-emphasis-inverse.mb-4,
.card.bg-warning.text-high-emphasis-inverse.mb-4,
.card.bg-info.text-high-emphasis-inverse.mb-4,
.card.bg-danger.text-high-emphasis-inverse.mb-4 {
  padding-bottom: 2rem;
  line-height: 2rem;
}
.card.bg-primary.text-high-emphasis-inverse.mb-4.widgetA {
  background: #1abc9c !important;
}
.card.bg-info.text-high-emphasis-inverse.mb-4.widgetB {
  background: #2ecc71 !important;
}

.card.bg-warning.text-high-emphasis-inverse.mb-4.widgetC {
  background: #3498db !important;
}
.card.bg-danger.text-high-emphasis-inverse.mb-4.widgetD {
  background: #9b59b6 !important;
}
.card.bg-primary.text-high-emphasis-inverse.mb-4.widgetE {
  background: #34495e !important;
}
.card.bg-info.text-high-emphasis-inverse.mb-4.widgetF {
  background: #f1c40f !important;
}
.card.bg-warning.text-high-emphasis-inverse.mb-4.widgetG {
  background: #e67e22 !important;
}
.card.bg-danger.text-high-emphasis-inverse.mb-4.widgetH {
  background: #e74c3c !important;
}
button.btn.btn-info {
  border-color: #f56001;
}
.table-thead > tr > th {
  background: linear-gradient(45deg, #39685f, #08c5dd);
  color: white;
}
.avatarimgProfile > img {
  height: 2.5rem;
}
table.table > thead > tr > th {
  background: white;
  color: #333;
  border-bottom: solid 2px #f56001;
}
.sidebar-nav {
  background: #333333;
}

.custom-card-header,
.primary-btn-bg-color,
.add-more-icon-circle-wrapper,
button.btn.btn-primary.w-100 {
  background: #f56001 !important;
  color: white;
  border-color: #39685f;
}
.add-more-icon-circle-wrapper {
  display: inline-block;
  border-radius: 50%;
  padding: 10px;
  margin-right: 14rem;
}
/*thead.ant-table-thead:hover,
thead.ant-table-thead > tr:hover,
thead.ant-table-thead > tr > th:hover {
  color: #39685f !important;
  background: white !important;
}*/
th.ant-table-cell.ant-table-column-has-sorters:hover {
  color: #f56001 !important;
  background: white !important;
}
.ant-table-filter-column .anticon.anticon-search:hover {
  color: #f56001 !important;
  background: white !important;
}
button.btn.btn-primary,
button.btn.btn-primary.ms-4 {
  background: #f56001 !important;
  color: white;
  border-color: #f56001 !important;
}
.ant-message-custom-content.ant-message-success,
.ant-message-custom-content.ant-message-error {
  width: 24rem;
  height: 4.5rem;
  padding: 0.8rem;
}

.ant-message-notice-wrapper {
  margin-left: 30%;
  font-size: 16px;
}
.icon:not(.icon-c-s):not(.icon-custom-size).icon-lg {
  color: #ccc;
}
svg.svg-inline--fa.fa-house {
  color: #f56001 !important;
}
textarea.ruleTextarea {
  height: 8rem !important;
  width: 100% !important;
}
.textWrap > .form-floating {
  width: 100%;
}
.errMsg {
  font-size: 13px;
  color: #d22;
}
/***new styles -color -related ***/
